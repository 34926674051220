<template>
  <div>
    <c-table
      ref="iputPeriod"
      title="투입기간"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :columnSetting="false"
      :isExcelDown="false"
      :isExpan="false"
      :usePaging="false"
      :isFullScreen="false"
      :filtering="false"
      :editable="editable"
      selection="multiple"
      rowKey="mdmMaintenanceId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addRow" />
          <c-btn
            v-if="editable"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="grid.data"
            :mappingType="saveType"
            label="저장"
            icon="save"
            @beforeAction="saveRow"
            @btnCallback="saveCallback" />
          <c-btn
            v-if="editable" label="삭제" icon="remove" @btnClicked="deleteRow" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-history',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmEquipmentId: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      grid: {
        columns: [
        ],
        data: [],
        height: '500px'
      },
      listUrl: '',
      saveUrl: '',
      isSave: false,
      saveType: 'POST',
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.cim.vendor.input.list.url;
      this.saveUrl = transactionConfig.mdm.cim.vendor.input.save.url;
      this.deleteUrl = transactionConfig.mdm.cim.vendor.input.delete.url;
      // code setting
      // list setting
      this.getList();
      this.setHeader();
    },
    setHeader() {
      this.$comm.getComboItems('SAFETY_VENDOR_ORG_ROLE').then(roleFlag => {
        this.grid.columns = [
          {
            name: 'projectRoleCd',
            field: 'projectRoleCd',
            valueName: 'codeName',
            label: '역할',
            align: 'center',
            style: "width:100px",
            sortable: false,
            type: 'select',
            comboItems: roleFlag,
          },
          {
            required: true,
            name: 'inputDates',
            field: 'inputDates',
            label: '투입기간',
            align: 'center',
            range: true,
            style: 'width:180px',
            sortable: false,
            type: 'date',
          },
        ];
      });
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        mdmEquipmentId: this.popupParam.mdmEquipmentId
      };
      this.$http.request((_result) => {
        this.grid.data = _result.data;

        this.$_.forEach(this.grid.data, _item => {
          if (_item.inputStartDate && _item.inputEndDate) {
            _item.inputDates = [_item.inputStartDate, _item.inputEndDate];
          }
        })
      },);
    },
    addRow() {
      this.grid.data.splice(0,0, {
        mdmMaintenanceId: uid(),
        userId: this.popupParam.userId,
        inputDates: [],
        inputStartDate: '',
        inputEndDate: '',
        regUserId: this.$store.getters.user.userId, //등록자 ID
        remark: '',
        editFlag: 'C',
      })
    },
    saveRow() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.grid.data, _item => {
              if (_item.inputDates && _item.inputDates.length > 0) {
                _item.inputStartDate = _item.inputDates[0]
                _item.inputEndDate = _item.inputDates[1]
              }
            })
            this.isSave = !this.isSave
          },  
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    }, 
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    deleteRow() {
      let selectData = this.$refs['iputPeriod'].getSelected();
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  },
};  
</script>
